<template>
<div id="cua_decision_support_tool">
    <v-app-bar
      color="white"
      :fixed="false"
      flat
      dark
      height="100"
      max-width="1200"
    >
        <div style="height:100%; width:30px; background-color: #261062;"/>
        <div style="height:100%; width:10px; background-color: white;"/>
        <div style="height:100%; width:10px; background-color: #E9212E;"/>
        <v-spacer/>
        <div class="d-flex align-center" style="margin: 5px 0px 0px 50px;">
          <v-img
            alt="CUA"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            :src="images.cua.logo"
            width="600"
          />
          <v-img
            alt="CUA"
            class="shrink mt-1 visible-sm-and-down hidden-md-and-up"
            contain
            min-width="80"
            :src="images.cua.logo_crest"
            width="80"
          />
        </div>
      <v-spacer/>

      <div style="width:100px;margin-top: 15px;">
          <Language/>
      </div>
    </v-app-bar>
    

    
    
    <v-container>
        <v-row style="line-height: 35px;">
            <v-col align="center">
                <div style="font-size: 21pt;">
                    <Str :index="'tool > title'"/>
                </div>
            </v-col>
        </v-row>
        <v-row style="padding: 0px; margin: 15px 0px 0px 0px;">
            <v-col align="center" style="padding:0px;">
                <v-progress-linear
                    background-color="#E9212E"
                    color="#261062"
                    :value="progress"
                    height="8"
                />
            </v-col>
        </v-row>
        <v-row v-if="states.renderQuestions" style="padding: 0px; margin: 0px;">
            <v-col style="padding:0px;">
                <v-container style="max-width: 800px; padding: 0px 0px 35px 0px;" data-test-tabs>
                    <v-row>
                        <v-col cols="12" md="4" align="center">
                            <button data-test-tab="survey" @click="tab='survey'" :class="['step-tab', {'active':tab=='survey'}, {'complete':stepCompletion.survey}]">
                                <div class="step-tab-content">
                                    <v-icon style="display:block; color: white;">mdi-account-box</v-icon>
                                    <Str :index="'tabs > survey'"/>
                                </div>
                                <div class="step-tab-carrot"/>
                            </button>
                        </v-col>
                        <v-col cols="12" md="4" align="center">
                            <button data-test-tab="recommendations" @click="tab='recommendations'" :disabled="!stepCompletion.survey" :class="['step-tab', {'disabled':!stepCompletion.survey}, {'active':tab=='recommendations'}, {'complete':stepCompletion.recommendations}]">
                                <div class="step-tab-content">
                                    <v-icon style="display:block; color: white;">mdi-book-open</v-icon>
                                    <Str :index="'tabs > recommendations'"/>
                                </div>
                                <div class="step-tab-carrot"/>
                            </button>
                        </v-col>
                        <v-col cols="12" md="4" align="center">
                            <button data-test-tab="summary" @click="tab='summary'" :disabled="!stepCompletion.recommendations" :class="['step-tab', {'disabled':!stepCompletion.recommendations}, {'active':tab=='summary'}, {'complete':stepCompletion.summary}]">
                                <div class="step-tab-content">
                                    <v-icon style="display:block; color: white;">mdi-playlist-check</v-icon>
                                    <Str :index="'tabs > summary'"/>
                                </div>
                                <div class="step-tab-carrot"/>
                            </button>
                        </v-col>
                    </v-row>
                </v-container>
                <v-tabs
                    v-model="tab"
                    icons-and-text
                    centered
                    slider-size="0"
                    active-class="step-active"
                    >            
                    <v-tab v-show="false" href="#survey"/>
                    <v-tab v-show="false" href="#recommendations"/>
                    <v-tab v-show="false" href="#summary"/>

                    <v-tabs-items v-model="tab">
                        <v-tab-item data-test-tabItem="survey" :value="'survey'">
                            <v-container>
                                <v-row>
                                    <v-col>                                    
                                        <Question v-for="(question, questionIndex) in questions" v-model="questions[questionIndex].form" :debug="debug" :language="language" :images="images" :question="question" :info="info" @questionUpdate="questionUpdate" :key="question.id" v-show="question.display"/>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col align="center">
                                        <v-btn v-if="allow('recommendations')" color="#00A8CB" @click="tab='recommendations'" dark :disabled="allow('recommendations')===false" data-test-survey-continue>
                                            <Str :index="'button > continue'"/>
                                        </v-btn>
                                        <Str v-else :index="'survey > please answer all questions'"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>


                        <v-tab-item data-test-tabItem="recommendations" :value="'recommendations'">
                            <template v-if="debug">
                                <json-viewer :value="profiles" :expand-depth="3"></json-viewer>
                                <v-btn fab x-small @click="test.phase=1">1</v-btn> | 
                                <v-btn fab x-small @click="test.phase=2">2</v-btn> | 
                                <v-btn fab x-small @click="test.phase=3">3</v-btn> | 
                                <v-btn fab x-small @click="test.phase=4">4</v-btn> | 
                                <v-btn fab x-small @click="test.phase=null">X</v-btn>
                            </template>

                            <v-card v-if="ready && tools().object.anyTrue(ui_phase)">
                                <v-card-text>
                                    <template v-for="(recommendation, recommendationID) in recommendations">
                                        <Recommendation v-model="KT_selection" :debug="debug" :language="language" :ui_phase="ui_phase" :kt_content="kt_content" :recommendation="recommendation" :recommendationID="recommendationID" :key="'reco_'+recommendationID"/>
                                    </template>



                                </v-card-text>

                                <v-card-actions>
                                <v-spacer/>
                                    <v-btn v-if="KT_selection.length>0" color="#00A8CB" @click="tab='summary'" dark :disabled="allow('recommendations')===false" data-test-recommendation-continue>
                                        <Str :index="'button > continue'"/>
                                    </v-btn>
                                    <template v-else>
                                        <Str :index="'recommendations > column > select treatment'"/>
                                    </template>
                                <v-spacer/>  
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item data-test-tabItem="summary" :value="'summary'" v-if="tab=='summary'">
                            <div class="summary">
                                <v-container>
                                    <v-row>
                                        <v-col col="12" align="center">
                                            <div v-html="summary.title[language]" data-test-summary-section="title" style="color: #261062; margin: 15px; font-weight: bold; font-size: 1.5em;"/>
                                            <div v-html="summary.body[language]" data-test-summary-section="body" style="color: #261062; margin-bottom: 25px; font-weight: bold; font-size: 1em;"/>
                                        </v-col>
                                    </v-row>

                                    <template v-for="(summaryRow, summaryIndex) in summary.rows">
                                        <v-row class="summary-alt-grey" v-if="questionMap[summaryRow.qid].display" :key="'summary_row_'+summaryIndex">
                                            <v-col sm="12" md="10" class="summary-col-1">
                                                {{summaryRow.text[language]}}
                                            </v-col>
                                            <v-col sm="12" md="2" align="center" v-if="form_data[summaryRow.qid].group[1].value">
                                                {{answerLabel(questionMap[summaryRow.qid],form_data[summaryRow.qid]).group[1].text[language]}}
                                            </v-col>
                                        </v-row>                                    
                                    </template>

                                    <v-row>
                                        <v-col sm="12" md="6" class="summary-col-1" style="border: none;">
                                            <Str index="summary > recommendations"/>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="KT_selection.includes(8)">
                                        <v-col sm="12" md="12">
                                            <Treatment :kt_content="kt_content" :kt_id="8" :language="language"/>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="KT_selection.includes(5)">
                                        <v-col sm="12" md="12">
                                            <Treatment :kt_content="kt_content" :kt_id="5" :language="language"/>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="KT_selection.includes(6)">
                                        <v-col sm="12" md="12">
                                            <Treatment :kt_content="kt_content" :kt_id="6" :language="language"/>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="KT_selection.includes(7)">
                                        <v-col sm="12" md="12">
                                            <Treatment :kt_content="kt_content" :kt_id="7" :language="language"/>
                                        </v-col>
                                    </v-row>
                                            
                                    <v-row v-if="KT_selection.includes(9)">
                                        <v-col sm="12" md="12">
                                            <Treatment :kt_content="kt_content" :kt_id="9" :language="language"/>
                                        </v-col>
                                    </v-row>
                                            
                                    <v-row v-if="KT_selection.includes(10)">
                                        <v-col sm="12" md="12">
                                            <Treatment :kt_content="kt_content" :kt_id="10" :language="language"/>
                                        </v-col>
                                    </v-row>                                            
                                </v-container>

                                <div id="pdf_html" v-if="states.pdf || debug" v-show="debug" style="border: 1px solid grey;">
                                    <table style="width: 100%; font-size: 9pt; font-family: Roboto, sans-serif;">
                                        <thead>
                                            <tr>
                                                <td colspan='2' style="position: relative;text-align: center;">
                                                    <div style="position: absolute; left:0px;">
                                                    <div style="height: 75px; display:inline-block; width:30px; background-color: #261062;"/>
                                                    <div style="height: 75px; display:inline-block; width:10px; background-color: white;"/>
                                                    <div style="height: 75px; display:inline-block; width:10px; background-color: #E9212E;"/>
                                                    </div>
                                                    
                                                    <img
                                                        style="display: inline-block; width: 500px;"
                                                        :src="images.cua.logo"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan='2'>
                                                    <div style="width:100%; height: 2px; background-color: #261062; margin-bottom: 15px;"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan='2' style="text-align: center;">
                                                    <div data-test-summary-section="title" v-html="summary.title[language]" style="color: #261062; font-weight: bold; font-size: 1.3em;"/>
                                                    <div data-test-summary-section="body" v-html="summary.body[language]" style="color: #261062; margin-bottom: 10px; font-weight: bold; font-size: 1em;"/>
                                                </td>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <template v-for="(summaryRow, summaryIndex) in summary.rows">
                                                <tr class="summary-alt-grey" v-if="questionMap[summaryRow.qid].display" :key="'summary_pdf_row_'+summaryIndex">
                                                    <td width="85%" style="font-weight: bold; padding: 5px 25px 5px 25px; vertical-align:top;" class="summary-col-1">
                                                        {{summaryRow.text[language]}}
                                                    </td>
                                                    <td style="text-align:center;" v-if="form_data[summaryRow.qid].group[1].value">
                                                        {{answerLabel(questionMap[summaryRow.qid],form_data[summaryRow.qid]).group[1].text[language]}}
                                                    </td>
                                                </tr>                                    
                                            </template>
                                        </tbody>
                                    </table>

                                    <div style="padding: 15px;">
                                        <h5 style='text-align:center;margin: 5px;'>
                                            <Str index="summary > recommendations"/>
                                        </h5>

                                        <div v-if="KT_selection.includes(8)" :class="[{'pageBreak':KT_selection.length>1}]">
                                            <Treatment :kt_content="kt_content" :kt_id="8" :language="language" :pdf_style="true"/>
                                        </div>

                                        <div v-if="KT_selection.includes(5)" :class="[{'pageBreak':KT_selection.length>1}]">
                                            <Treatment :kt_content="kt_content" :kt_id="5" :language="language" :pdf_style="true"/>
                                        </div>

                                        <div v-if="KT_selection.includes(6)" :class="[{'pageBreak':KT_selection.length>1}]">
                                            <Treatment :kt_content="kt_content" :kt_id="6" :language="language" :pdf_style="true"/>
                                        </div>

                                        <div v-if="KT_selection.includes(7)" :class="[{'pageBreak':KT_selection.length>1}]">
                                            <Treatment :kt_content="kt_content" :kt_id="7" :language="language" :pdf_style="true"/>
                                        </div>
                                            
                                        <div v-if="KT_selection.includes(9)" :class="[{'pageBreak':KT_selection.length>1}]">
                                            <Treatment :kt_content="kt_content" :kt_id="9" :language="language" :pdf_style="true"/>
                                        </div>
                                        <div v-if="KT_selection.includes(10)" :class="[{'pageBreak':KT_selection.length>1}]">
                                            <Treatment :kt_content="kt_content" :kt_id="10" :language="language" :pdf_style="true"/>
                                        </div>
                                    </div>
                                </div>
                                <v-container>
                                    <v-row>
                                        <v-col align="center">
                                            <v-progress-circular
                                            v-if="states.generatingPDF"
                                            indeterminate
                                            color="primary"
                                            />
                                            <template v-else>
                                                <v-btn v-if="!pdfLink.url|| debug" @click="getPDF" color="#261062" dark>
                                                    <Str index="button > download"/>
                                                </v-btn>
                                                <v-btn v-if="pdfLink.url || debug" color="#261062" dark :link="true" :target="'_blank'" :href="pdfLink.url">
                                                    <Str index="button > ready"/>
                                                    <v-icon right style="color:white;">mdi-cloud-download</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-col>
        </v-row>

        <!-- <v-row>
            <v-col align="center">             
                <v-footer fixed color="white">
                    <v-col style="border-top: 3px solid red;">

                    </v-col>
                </v-footer>
            </v-col>
        </v-row> -->

    </v-container>

    <v-footer style="background-color: transparent;">
        <v-container>
            <v-row>
                <v-col align="center">
                    <v-progress-linear
                        background-color="#E9212E"
                        color="#261062"
                        :value="progress"
                        height="8"
                    />
                    <Str data-test-acknowledgment index="UI > footer > acknowledgement" style="display:block; margin: 15px;"/>
                    <Str index="UI > footer > all rights reserved"/>
                </v-col>
            </v-row>
            <v-row v-if="allowDebug">
                    <v-switch  :true-value="true" :false-value="false" v-model="debug" :label="'debug'" data-test-debug style="position: absolute;"/>
                    <div v-if="debug">
                        <v-btn x-small @click="reset">Reset</v-btn>
                        <v-btn x-small @click="linkImages">linkImages</v-btn>
                        <v-btn x-small @click="$store.commit('language','fr')" data-test-ui-language>Unit Test - FR</v-btn>
                    </div>
            </v-row>            
        </v-container>
    </v-footer>
</div>
</template>

<script>
const init = require('../api_data.js')
const tools = {
    object: {
        isArray: (input)=>{
            return input!=null && typeof input=='object' && input.length>=0
        },
        filter: (list, remove)=>{
            let filterList = typeof remove=='string' ? [remove] : remove
            let filtered = list.filter(
                function(e) {
                    return this.indexOf(e) < 0;
                },
                filterList
            )

            return filtered
        },
        anyTrue: (input)=>{
            for(let i in input){
                if(input[i]===true){
                    return true
                }
            }
            return false
        }
    }
}
import cua_logo from '@/components/Surveys/CUA/assets/logos/logo_cua.png'
import cua_logo_crest from '@/components/Surveys/CUA/assets/logos/logo_cua_crest.png'
import Question from './Question'
import Recommendation from './Recommendation'
import Treatment from './treatment'

export default {
    props: {
        algorithmInfo: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.sync_model()
        this.init()
    },
    components: {
        Question,
        Recommendation,
        Treatment
    },
    data: function(){
        return {
            ready: false,
            debug: false,
            tab: null,
            questions: null,
            info: {},
            test: {
                phase: null
            },
            kt_content: null,
            KT_selection: [],
            api_data: {},
            states: {
                renderQuestions: false,
                pdf: false,
                generatingPDF: false
            },
            pdfLink: {},
            recommendations: {},
            summary: {},
            timer: null
        }
    },
    methods: {
        tools: function(){
            return tools
        },
        reset: function(){
            let self = this
            self.$router.push('/?debug=true')
        },
        init: function(){
            let self = this

            let questions = new Promise((resolve, reject)=>{
                self.sendRequest('get',self.api+'/getQuestions/cua').then(function(response){
                    self.$store.commit('labels',response.data.labels)
                    self.recommendations = response.data.recommendations
                    self.summary = response.data.summary
                    self.kt_content = response.data.kt_content
                    let questions = response.data.questions
                    self.$set(self.questions = [])
                    for(let i=0; i<questions.length; i++){
                        let question = questions[i]
                        question.form = {}
                        self.makeActive(self.questions, i, question)
                    }

                    let info = response.data.info
                    for(let key in info){
                        let infoItem = info[key]
                        infoItem.form = {}
                        self.makeActive(self.info, key, infoItem)
                    }
                    resolve()
                },function(response){
                    reject(response)
                })
            })

            let promises = [
                questions
            ]

            Promise.all(promises).then(()=>{
                // self.questionMap[1].groups[0].constraints = self.algorithmInfo.valueMap.gleason_score.model.constraints
                // self.questionMap[1].groups[0].subType='slider'
                self.states.renderQuestions = true
                setTimeout(function(){
                    // self.linkImages()
                    self.ready = true
                },800)
            })

        },
        linkImages: function(){
            let imageElems = document.querySelectorAll("img")
            for(let i=0; i<imageElems.length; i++){
                let elem = imageElems[i]
                let target = elem.getAttribute('src')
                // let src = self.images[target]

                let relink = target.search("data:image/png;base64")<0 && target.search("http")<0

                if(relink){
                    elem.src = window.location.origin+target
                }
            }            
        },
        returnMap: function(array, index){
            let output = {}

            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item[index]] = item
            }


            return output
        },
        sync_model: function(){
            let self = this
            let promises = []
            promises.push(new init.createKeys(self))
            promises.push(new init.syncFactors(self))
            promises.push(new init.syncValues(self))
            Promise.all(promises).then(()=>{
                self.$forceUpdate()
            })
        },
        makeActive: function(target, item, source){
            let self = this
            self.$set(target, item, source)
        },
        customUpdate: function(source){
            // let form_data = this.form_data
            this.KT_selection = []
            let api_data = this.api_data

            /*
            TODO:
            - investigate repeated null change.  

            */

            if(source=='question'){
                // if(form_data[2].group[1].value=='yes'){
                //     setTimeout(function(){
                //         form_data[2].group[1].value=null
                //     },800)
                //     alert('An exit message should be displayed')
                // }

                // if(form_data[4].group[1].value.length>0 && !form_data[4].group[1].value.includes('none')){
                //     form_data[100].group[1].value = 'true'
                // }else if(form_data[4].group[1].value.includes('none')){
                //     form_data[100].group[1].value = 'false'
                // }
                
                // this.questionMap[3].display = api_data.factors.has_visceral_mets=='true'
                this.questionMap[4].display = ['low','intermediate','high'].includes(api_data.factors.bone_mets)
                // this.questionMap[4].display = this.form_data[3].group[1].value!=null ? parseInt(this.form_data[3].group[1].value,10)>0 : false
                // this.questionMap[5].display = this.form_data[3].group[1].value!=null ? parseInt(this.form_data[3].group[1].value,10)>0 : false
                this.questionMap[7].display = this.form_data[6].group[1].value=='recurrent' ? true : false
            }

            if(source=='api_data'){
                // if(api_data.factors.has_visceral_mets!=null && api_data.factors.has_visceral_mets.length>0){
                //     this.form_data[4].group[1].value = api_data.factors.has_visceral_mets
                // }
                // if(api_data.factors.mscpc_occurence!=null && api_data.factors.mscpc_occurence.length>0){
                //     this.form_data[4].group[1].value = api_data.factors.mscpc_occurence
                // }
                // if(api_data.factors.radiation_therapy!=null && api_data.factors.radiation_therapy.length>0){
                //     this.form_data[4].group[1].value = api_data.factors.radiation_therapy
                // }
                // this.questionMap[1].form.group[1].value = api_data.values.gleason_score
                // this.questionMap[3].form.group[1].value = api_data.values.bone_mets
                // this.questionMap[4].form.group[1].value = api_data.values.bone_mets_axial
                // this.questionMap[5].form.group[1].value = api_data.values.bone_mets_appendicular
            }
        },
        questionUpdate: function(data){
            function includeFactors(forInclusion, api_data, algorithmInfo){
                for(let factorKey in forInclusion){
                    let list = forInclusion[factorKey]
                    api_data.factors[factorKey]
                    if(typeof api_data.factors[factorKey]=='object'){

                        let factor = algorithmInfo.factorMap[factorKey]
                        if(factor){
                            if(!factor.options.multiselect){
                                api_data.factors[factorKey] = list[0]
                            }else{
                                let filtered = tools.object.filter(api_data.factors[factorKey], list)
                                api_data.factors[factorKey] = filtered.concat(list)
                            }
                        }
                    }else{
                        //If global factors set as string
                        if(typeof api_data.factors[factorKey]=='string' && !list.includes(api_data.factors[factorKey])){
                            api_data.factors[factorKey] = list[0]
                        }
                    }
                }
            }

            function removeFactors(forRemoval, api_data){
                for(let factorKey in forRemoval){
                    let remove = forRemoval[factorKey]
                    api_data.factors[factorKey]
                    if(api_data.factors[factorKey]!=null && typeof api_data.factors[factorKey]=='object' && api_data.factors[factorKey].length>0){
                        //Remove if global factors is an array - only remove mapped items
                        let filtered = tools.object.filter(api_data.factors[factorKey], remove)
                        api_data.factors[factorKey] = filtered
                    }else{
                        //Remove if global factors set as string
                        if(typeof api_data.factors[factorKey]=='string' && remove.includes(api_data.factors[factorKey])){
                            api_data.factors[factorKey] = []
                        }
                    }
                }
            }            

            let questionMapping = this.questionMapping
            let question_id = data.question.id
            let form_data = data.form

            if(questionMapping[question_id]){
                let question = questionMapping[question_id]
                
                for(let group_id in question.group){
                    let group = question.group[group_id]

                    let multiple = tools.object.isArray(form_data.group[group_id].value)
                    
                    let mappings = group.choice ? group.choice : group.value ? group.value : null
                    let answer = form_data.group[group_id].value!=null && (form_data.group[group_id].value.length>0 || typeof form_data.group[group_id].value=='number') ? typeof form_data.group[group_id].value=='object' ? form_data.group[group_id].value : [form_data.group[group_id].value] : null
                    
                    if(group.choice){
                        let triggerSet = answer!=null ? Object.keys(mappings).some(r=>answer.indexOf(r)>=0) : false
                        
                        if(triggerSet){
                            //Add mapping items
                            let forInclusion = {
                                factors: {},
                                values: {}
                            }
    
                            for(let a=0; a<answer.length; a++){
                                let value = answer[a]
                                let items = mappings[value]
    
                                for(let i=0; i<items.length; i++){
                                    let item = items[i]
                                    if(!forInclusion[item.container][item.key]){
                                        forInclusion[item.container][item.key] = []
                                    }
    
                                    if(!forInclusion[item.container][item.key].includes(item.value)){
                                        forInclusion[item.container][item.key].push(item.value)
                                    }
                                }
    
                            }
                            
                            if(Object.keys(forInclusion.factors).length>0){
                                includeFactors(forInclusion.factors, this.api_data, this.algorithmInfo)
                            }  
                            
                            if(multiple){
                                let forRemoval = {
                                    factors: {},
                                    values: {}
                                }
    
                                for(let choice_trigger in mappings){
                                    if(!answer.includes(choice_trigger)){
                                        let items = mappings[choice_trigger]
            
                                        for(let i=0; i<items.length; i++){
                                            let item = items[i]
                                            if(!forRemoval[item.container][item.key]){
                                                forRemoval[item.container][item.key] = []
                                            }
                                            if(!forRemoval[item.container][item.key].includes(item.value)){
                                                forRemoval[item.container][item.key].push(item.value)
                                            }
                                        }
                                    }
                                }
                                if(Object.keys(forRemoval.factors).length>0){
                                    removeFactors(forRemoval.factors, this.api_data)
                                }
                            }
    
                        }else{
                            //Clear by mapping items
                            let forRemoval = {
                                factors: {},
                                values: {}
                            }
                            for(let choice_trigger in mappings){
                                let items = mappings[choice_trigger]
    
                                for(let i=0; i<items.length; i++){
                                    let item = items[i]
                                    if(!forRemoval[item.container][item.key]){
                                        forRemoval[item.container][item.key] = []
                                    }
                                    if(!forRemoval[item.container][item.key].includes(item.value)){
                                        forRemoval[item.container][item.key].push(item.value)
                                    }
                                }
                            }
    
                            if(Object.keys(forRemoval.factors).length>0){
                                // console.log('no trigger - clear',{
                                //     forRemoval,
                                //     mappings
                                // })
                                removeFactors(forRemoval.factors, this.api_data)
                            }
                        }                        
                    }
                    
                    if(group.value){
                        this.api_data.values[mappings.key] = answer && answer.length>0 ? answer[0] : null
                    }
                }
            }

            if(this.ready && this.customUpdate){
                this.customUpdate('question')
            }
        },
        api_dataUpdate: function(){
            function filter(list, remove){
                let filterList = typeof remove=='string' ? [remove] : remove
                let filtered = list.filter(
                    function(e) {
                        return this.indexOf(e) < 0;
                    },
                    filterList
                )

                return filtered
            }
            let form_data = this.form_data
            let api_data = this.api_data
            let reverseLookup = this.reverseQuestionMapping
            let questionMap = this.questionMap
            for(let factorKey in reverseLookup.factors){
                let global = api_data.factors[factorKey]

                //Empty Global - Clear linked choices
                if(global==null || (global!=null && global.length==0)){
                    let targets = reverseLookup.factors[factorKey]
                    
                    for(let factorValue in targets){
                        let questions = targets[factorValue]

                        for(let i=0; i< questions.length; i++){
                            let question = questions[i]
                            let value = form_data[question.question_id].group[question.group_id].value

                            if(typeof value=='string' && value==question.choice_value){
                                // console.log('api clear string',{
                                //     value, question
                                // })
                                form_data[question.question_id].group[question.group_id].value = null
                            }
                            
                            if(value!=null && typeof value=='object' && value.includes(question.choice_value)){
                                // console.log('api clear object',{
                                //     value, question
                                // })
                                form_data[question.question_id].group[question.group_id].value = filter(form_data[question.question_id].group[question.group_id].value, question.choice_value)
                            }
                        }
                    }
                }
                
                
                //Has global - Set linked choices
                if(global!=null && global.length>0){
                    if(typeof global=='string'){
                        let targets = reverseLookup.factors[factorKey][global]
                        for(let index in targets){
                            let question = targets[index]
                            if(questionMap[question.question_id]){
                                form_data[question.question_id].group[question.group_id].value = question.choice_value
                            }else{
                                console.error('Missing mapping target',question)
                            }
                        }
                    }
                    if(typeof global=='object'){
                        let targets = reverseLookup.factors[factorKey]

                        for(let factorValue in targets){
                            let questions = targets[factorValue]

                            if(global.includes(factorValue)){
                                // console.log('api - check')
                                for(let i=0; i< questions.length; i++){
                                    let question = questions[i]
                                    if(!form_data[question.question_id].group[question.group_id].value.includes(question.choice_value)){
                                        form_data[question.question_id].group[question.group_id].value.push(question.choice_value)
                                    }
                                }
                            }else{
                                // console.log('api - uncheck')
                                for(let i=0; i< questions.length; i++){
                                    let question = questions[i]
                                    if(form_data[question.question_id].group[question.group_id].value.includes(question.choice_value)){
                                        form_data[question.question_id].group[question.group_id].value = filter(form_data[question.question_id].group[question.group_id].value, question.choice_value)
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if(this.ready && this.customUpdate){
                this.customUpdate('api_data')
            }
        },
        getPDF: function(){
            let self = this
            self.states.pdf = true
            self.states.generatingPDF = true
            // self.linkImages()

            setTimeout(function(){
                let html = document.getElementById('pdf_html').innerHTML
                self.sendRequest('post',self.api+'/pdf/make/cua',{
                    html
                }).then(function(response){
                    self.states.generatingPDF = false
                    self.pdfLink = response.data
                    window.open(self.pdfLink.url,'Summary')
                })
                self.states.pdf = false
            },1000)
        },
        expires: function(ttl){
            let d = new Date();
            d.setSeconds(d.getSeconds()+ttl)
            return d
        },
        allow: function(tab){
            if(this.debug){
                return true
            }
            let questionMap = this.questionMap
            if(this.ready){
                if(tab=='recommendations'){
                    for(let qid in questionMap){
                        let question = questionMap[qid]

                        if(question.display){
                            for(let groupID in question.form.group){
                                let group = question.form.group[groupID]
                                if(group.value===null){
                                    return false
                                }
                            }
                        }
                    }
                    return true
                }
            }
            return false
        },
        answerLabel: function(question, answer){
            let output = {
                group: {}
            }

            let groupMap = this.returnMap(question.groups, 'id')

            for(let gid in answer.group){
                let group = groupMap[gid]
                if(!output.group[gid]){
                    output.group[gid] = group.type == 'checkbox' ? [] : null
                }
                let choiceMap = this.returnMap(groupMap[gid].choices, 'value')
                let value = answer.group[gid].value

                if(tools.object.isArray(value)){
                    for(let i=0; i<value.length; i++){
                        let checked = value[i]
                        output.group[gid].push(choiceMap[checked])
                    }
                }else{
                    let choice = choiceMap[value]
                    if(choice){
                        output.group[gid] = choice
                    }
                }              
            }

            return output
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        api: function(){
            return this.$store.getters.api[this.$store.getters.env]
        },
        allowDebug: function(){
            return this.$route.query.debug=='true'
        },
        form_data: function(){
            let output = {}
            let questions = this.questions

            if(questions!=null){
                for(let i=0; i<questions.length; i++){
                    let question = questions[i]
                    output[question.id] = question.form
                }
    
                return output
            }

            return null
        },
        questionMap: function(){
            let questions = this.questions
            let output = {}
            if(questions){
                output = this.returnMap(questions,'id')
            }

            return output
        },
        questionMapping: function(){
            let output = {}
            let questions = this.questions
            let algorithmInfo = this.algorithmInfo

                
                
            if(questions!=null && algorithmInfo!=null){
                output[1] = {
                    group: {
                        1: {
                            choice: {
                                'false': [{container: 'factors', key: 'gleason_grade_high', value: 'false'}],
                                'true': [{container: 'factors', key: 'gleason_grade_high', value: 'true'}]
                            }
                        }
                    }
                }                  
                output[2] = {
                    group: {
                        1: {
                            choice: {
                                'true': [{container: 'factors', key: 'has_visceral_mets', value: 'true'}],
                                'false': [{container: 'factors', key: 'has_visceral_mets', value: 'false'}]
                            }
                        }
                    }
                }
                output[3] = {
                    group: {
                        1: {
                            choice: {
                                'none': [{container: 'factors', key: 'bone_mets', value: 'none'}],
                                'low': [{container: 'factors', key: 'bone_mets', value: 'low'}],
                                'intermediate': [{container: 'factors', key: 'bone_mets', value: 'intermediate'}],
                                'high': [{container: 'factors', key: 'bone_mets', value: 'high'}]
                            }
                        }
                    }
                }
                output[4] = {
                    group: {
                        1: {
                            choice: {
                                'true': [{container: 'factors', key: 'bone_mets_outside_axial_skeleton', value: 'true'}],
                                'false': [{container: 'factors', key: 'bone_mets_outside_axial_skeleton', value: 'false'}]
                            }
                        }
                    }
                }
              
                output[6] = {
                    group: {
                        1: {
                            choice: {
                                'de_novo': [{container: 'factors', key: 'mscpc_occurence', value: 'de_novo'}],
                                'recurrent': [{container: 'factors', key: 'mscpc_occurence', value: 'recurrent'}]
                            }
                        }
                    }
                }
                output[7] = {
                    group: {
                        1: {
                            choice: {
                                'true': [{container: 'factors', key: 'radiation_therapy', value: 'true'}],
                                'false': [{container: 'factors', key: 'radiation_therapy', value: 'false'}]
                            }
                        }
                    }
                }

                return output
            }

            return null

        },
        reverseQuestionMapping: function(){
            let questionMapping = this.questionMapping
            let output = {
                factors: {},
                values: {}
            }

            for(let question_id in questionMapping){
                let question = questionMapping[question_id]

                for(let group_id in question.group){
                    let group = question.group[group_id]
                    
                    
                    for(let choice_value in group.choice){
                        let factors = group.choice[choice_value]
                        for(let i=0; i<factors.length; i++){
                            let factor = factors[i]
                            if(!output[factor.container][factor.key]){
                                output[factor.container][factor.key] = {}
                            }
                            if(!output[factor.container][factor.key][factor.value]){
                                output[factor.container][factor.key][factor.value] = []
                            }
                            if(!output[factor.container][factor.key][factor.value].includes(choice_value)){
                                output[factor.container][factor.key][factor.value].push({
                                    question_id, group_id, choice_value
                                })
                            }
                            // output[factor.container][factor.key].push({
                            //     question_id,
                            //     group_id,
                            //     choice_value
                            // })
                        }
                    }


                }
            }
            

            return output
        },
        images: function(){
            function resolve(target){
                let relink = target.search("data:image/png;base64")<0 && target.search("http")<0
                if(relink){
                    return window.location.origin+target
                }

                return target
            }
            return {
                cua: {
                    logo: resolve(cua_logo),
                    logo_crest: resolve(cua_logo_crest)
                }
            }
        },
        context: function(){
            if(this.ready){    
                let context = {
                    gleason_grade_high: this.api_data.factors.gleason_grade_high, //gleason_score(this.api_data.values.gleason_score),
                    visceral_mets: this.form_data[2].group[1].value!=null ? this.form_data[2].group[1].value=='true' ? 'bad' : 'less_bad' : null,
                    mscpc_occurence: this.api_data.factors.mscpc_occurence.length>0 ? this.api_data.factors.mscpc_occurence : null,
                    bone_mets: ['intermediate','high'].includes(this.api_data.factors.bone_mets) ? 'bad' : 'not_bad', //this.api_data.factors.bone_mets.length==0 || this.api_data.factors.bone_mets=='0' ? 'not_bad' : this.api_data.factors.bone_mets=='3' || this.api_data.factors.bone_mets=='>=4' ? 'bad' : 'not_bad',
                    radiation_therapy: this.api_data.factors.radiation_therapy.length>0 ? this.api_data.factors.radiation_therapy : null,
    
                }
    
                return context
            }else{
                return null
            }
        },
        profiles: function(){
            if(this.ready){
                let context = this.context
                
                let output = {
                    ui_1: {
                        debug: this.test.phase == 1,
                        rule_1: 
                            (context.gleason_grade_high=='false') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='not_bad') &&
                            (context.mscpc_occurence=='de_novo'),
                        rule_2: 
                            (context.gleason_grade_high=='false') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='not_bad') &&
                            (context.mscpc_occurence=='recurrent' && context.radiation_therapy=='false'),
                        rule_3: 
                            (context.gleason_grade_high=='false') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='bad') &&
                            (context.mscpc_occurence=='de_novo'),
                        rule_4: 
                            (context.gleason_grade_high=='false') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='bad') &&
                            (context.mscpc_occurence=='recurrent' && context.radiation_therapy=='false'),
                        rule_5: 
                            (context.gleason_grade_high=='true') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='not_bad') &&
                            (context.mscpc_occurence=='de_novo'),
                        rule_6: 
                            (context.gleason_grade_high=='true') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='not_bad') &&
                            (context.mscpc_occurence=='recurrent' && context.radiation_therapy=='false'),
                    },
                    ui_2: {
                        debug: this.test.phase == 2,
                        rule_1: 
                            (context.gleason_grade_high=='false') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='not_bad') &&
                            (context.mscpc_occurence=='recurrent' && context.radiation_therapy=='true'),
                        rule_2: 
                            (context.gleason_grade_high=='false') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='bad') &&
                            (context.mscpc_occurence=='recurrent' && context.radiation_therapy=='true'),
                        rule_3: 
                            (context.gleason_grade_high=='true') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='not_bad') &&
                            (context.mscpc_occurence=='recurrent' && context.radiation_therapy=='true')
                    },
                    ui_3: {
                        debug: this.test.phase == 3,
                        rule_1: 
                            (context.visceral_mets=='bad')
                    },
                    ui_4: {
                        debug: this.test.phase == 4,
                        rule_1: 
                            (context.gleason_grade_high=='true') &&
                            (context.visceral_mets=='less_bad') &&
                            (context.bone_mets=='bad')
                    }
                }
    
                return output
            }else{
                return null
            }
        },
        ui_phase: function(){
            if(this.ready){
                let profiles = this.profiles
                let output = {}
    
                for(let i in profiles){
                    output[i] = tools.object.anyTrue(profiles[i])
                }
    
                return output
            }else{
                return null
            }
        },
        stepCompletion: function(){
            return {
                survey: this.allow('recommendations'),
                recommendations: this.KT_selection.length>0,
                summary: this.tab=='summary'
            }
        },
        progress: function(){
            let progress = 0
            let completion = this.stepCompletion
            let steps = 0
            for(let i in completion){
                steps++
                if(completion[i]){
                    progress++
                }
            }

            return progress>0 ? Math.round((progress/steps)*100) : 0
        }
    },
    watch: {
        api_data: {
            deep: true,
            handler: function(){
                this.$emit('input',this.api_data)
                if(this.ready){
                    this.api_dataUpdate()
                }


            }
        },
        tab: function(){
            window.scrollTo(0, 0);
            this.pdfLink = {}
        },
        language: function(){
            this.pdfLink = {}
        },
        stepCompletion: {
            deep: true,
            handler: function(){
                if(!this.stepCompletion.survey){
                    this.tab = 'survey'
                }
            }
        }
    }
}
</script>

<style>
/* table {
    border-spacing: 1px;
}
table td {
    border: 1px solid lightgrey;
    border-collapse: collapse;
    padding: 5px;
} */
#cua_decision_support_tool .v-item-group.v-tabs-bar{
    display: none;
}

.v-tab{
    min-width: 200px !important;
    color: white !important;
    background-color: #261062;
    border: 3px solid white;
    overflow: visible;
}

.v-tab.step-complete{
    background-color: #38B448;
}

.step-tab{
    text-transform: uppercase;
    background-color: #261062;
    border: 3px solid #261062;
    color:  white;
    font-weight: 500;
    width: 100%;
    min-height: 50px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s linear;
}

.step-tab.active{
    background-color: #00A8CB;
    border: 3px solid #00A8CB;
}

.step-tab.complete{
    background-color: #38B448;
    border: 3px solid #38B448;
    transition: background-color 0.2s linear;
}

.step-tab.disabled{
    background-color: grey;
    border: 3px solid grey;
    transition: background-color 0.2s linear;
}

.step-tab.disabled:hover{
    border: 3px solid grey;
}

.step-tab:hover{
    border: 3px solid #00A8CB;
}

.step-tab-content{
    padding: 5px;
    position: relative;
    z-index: 100;
}

.step-tab-carrot{
    display: none;
    position: absolute;
    left: 50%;
    width: 35px;
    height: 35px;
    transform: rotate(45deg) translateX(-60%);
    bottom: -20px;
    background-color: inherit;
}
.step-tab.active .step-tab-carrot{
    display: block;
}

.summary .summary-alt-grey:nth-child(even){
    background-color: whitesmoke;
}

.summary-col-1{
    font-weight: bold;
}
.summary-col-1:first-child{
    border-right: 1px solid lightgrey;
}

@media only screen and (max-width: 600px) {
    .summary-col-1:first-child{
        border: none;
    }
}
</style>