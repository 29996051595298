<template>
<div>
    <!-- <v-app-bar
      color="white"
      :fixed="false"
      flat
      dark
      height="100"
      max-width="1200"
    >
        <div style="height:100%; width:30px; background-color: #261062;"/>
        <div style="height:100%; width:10px; background-color: white;"/>
        <div style="height:100%; width:10px; background-color: #E9212E;"/>
        <div class="d-flex align-center" @click="$router.push('/')">
          <v-img
            style="margin-left: 15px;"
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            :src="images.cua.logo"
            width="600"
          />
        </div>
      <v-spacer/>

      <div style="width:200px">
        <v-select
          color="blue"
          light
          dense
          outlined
          v-model="language"
          :items="[{text:'EN',value:'en'},{text:'FR',value:'fr'}]"
          label="Language"/>
      </div>
    </v-app-bar>        -->
  <v-container>
      <v-row>
          <v-col>
              <h3 align='center'>This application is under development.</h3>
              <p align='center'>
                This preview version is to help visualize user interactions, application functionality and clinical content.
              </p>

              <v-card>
                  <v-card-subtitle>Notes/Questions for discussion</v-card-subtitle>
                  <v-card-text>
                      <pre>
                    Notes
                      - Content has been roughed into the application without layout to confirm development direction, functionality and get feedback

                    Questions
                      - Graceful exit message if taking anticoagulants
                      - Clot location: Can patient have DVT & PE at the same time?  (wording for PDF if multiple)
                      - How is DOACs information invisioned for medications question.  Currently trigger is just positioned as a place holder of question subtext 

                    Feedback
                      - Additional info handling:  i.e. for items contributing to clots - suggest merging and repositioned to a central location below the relevant question.
                      - Summary 
                        Currently placed below the questionset and immediately updated to streamline testing.
                        PDF generation will be added in via download button as per manuscript
                        Information content is currently included (ie DVT, PE documents) under relevant sections
                      </pre>
                  </v-card-text>
              </v-card>
          </v-col>
      </v-row>
        <v-row>
            <v-col v-if="questions">
                <v-switch :true-value="true" :false-value="false" v-model="debug" :label="'debug'"/>
                <Question v-for="(question, questionIndex) in questions" v-model="questions[questionIndex].form" :debug="debug" :images="images" :question="question" :info="info" @questionUpdate="questionUpdate" :key="question.id" v-show="question.display || !question.system"/>
            </v-col>
        </v-row>
        <v-row v-if="ready">
            <v-col>
                <v-card>
                    <v-card-title>
                        PREVIEW
                        <v-card-subtitle>This represents the PDF content to be generated after submission</v-card-subtitle>
                    </v-card-title>

                    <v-card-text>
                        <div id="pdf_html">
                            <p>
                                We know that discovering you have a clot can be quite confusing and stressful. We hope this tool can help address some of your questions and support your conversation with your health care team.
                            </p>

                            <div v-if="api_data.factors.clot_location.includes('legs') || api_data.factors.clot_location.includes('lungs')">
                                <p>
                                    <b>You have been diagnosed with:</b>
                                    <ul>
                                        <li v-if="api_data.factors.clot_location.includes('legs')">
                                            <b>DVT</b>
                                            <hr>
                                            <div v-html="info.DVT.content.en"/>

                                        </li>
                                        <li v-if="api_data.factors.clot_location.includes('lungs')">
                                            <b>PE</b>
                                            <hr>
                                            <div v-html="info.PE.content.en"/>

                                        </li>
                                    </ul>
                                </p>

                                <p v-if="api_data.factors.provokted_vte=='true' || api_data.factors.provokted_vte=='false'">
                                    This clot was likely {{api_data.factors.provokted_vte=='true' ? 'provoked' : 'unprovoked'}}
                                </p>

                                <p>
                                    When you were diagnosed with 
                                    <template v-for="(location, locationIndex) in api_data.factors.clot_location">
                                        {{locationIndex>0 ? ',' : ''}} {{location=='legs' ? 'DVT' : location=='lungs' ? 'PE' : 'ERROR'}}
                                    </template>
                                    you were started on {{questionMap[5].form.group[1].value}}
                                </p>
                            </div>

                            <p v-if="questionMap[6].form.group[1].value.length>0 && !questionMap[6].form.group[1].value.includes('none')">
                                You wanted some additional information, please see below. Please review these with your healthcare team:
                                <ul>
                                    <li v-for="(choice, choiceIndex) in questionMap[6].form.group[1].value" :key="'additional_info_'+choiceIndex">
                                        {{returnMap(questionMap[6].groups[0].choices, 'value')[choice].text.en}}
                                        <div v-if="choice=='duration_of_therapy'" v-html="info.DoT.content.en"/>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="getPDF"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
  </v-container>
</div>
</template>

<script>
const init = require('../api_data.js')
const tools = {
    object: {
        isArray: (input)=>{
            return input!=null && typeof input=='object' && input.length>=0
        },
        filter: (list, remove)=>{
            let filterList = typeof remove=='string' ? [remove] : remove
            let filtered = list.filter(
                function(e) {
                    return this.indexOf(e) < 0;
                },
                filterList
            )

            return filtered
        }        
    }
}

import Question from './Question'
import arrow from './arrow.png'
import triangle from './triangle.png'

export default {
    props: {
        algorithmInfo: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.sync_model()
        this.init()
    },
    components: {
        Question
    },
    data: function(){
        return {
            ready: false,
            debug: false,
            questions: null,
            info: {},
            // algorithmInfo: null,
            api_data: {}
        }
    },
    methods: {
        linkImages: function(){
            let self = this
            let imageElems = document.querySelectorAll("img")
            for(let i=0; i<imageElems.length; i++){
                let elem = imageElems[i]
                let target = elem.getAttribute('src')
                let src = self.images[target]

                if(src){
                    elem.src = window.location.origin+self.images[target]
                }
            }            
        },        
        returnMap: function(array, index){
            let output = {}

            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item[index]] = item
            }


            return output
        },
        sync_model: function(){
            let self = this
            let promises = []
            promises.push(new init.createKeys(self))
            promises.push(new init.syncFactors(self))
            promises.push(new init.syncValues(self))
            Promise.all(promises).then(()=>{
                self.$forceUpdate()
            })
        },
        init: function(){
            let self = this

            // let algorithmInfo = new Promise((resolve, reject)=>{
            //     self.sendRequest('get',process.env.VUE_APP_API+'/getAlgorithmInfo').then(function(response){
            //         self.algorithmInfo = response.data
            //         resolve()
            //     },function(response){
            //         reject(response)
            //     })
            // })

            let questions = new Promise((resolve, reject)=>{
                self.sendRequest('get',process.env.VUE_APP_API+'/getQuestions/vte').then(function(response){
                    // let response = {
                    //     data: {
                    //         "questions": [
                    //             {
                    //             "id": 100,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "provoked_vte",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "radio",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "true",
                    //                         "fr": null
                    //                     },
                    //                     "value": "true",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "false",
                    //                         "fr": null
                    //                     },
                    //                     "value": "false",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": false,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": true
                    //             },
                    //             {
                    //             "id": 1,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "Is this your first VTE? ",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": "Venous Thromboembolism (VTE) is a general term encompassing a blood clot that forms in a vein that may / may not embolize (break free and travel to other places in the body). The term VTE encompasses both deep vein thrombosis (DVT) and pulmonary embolism (PE).",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "radio",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "First VTE",
                    //                         "fr": null
                    //                     },
                    //                     "value": "first",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "Recurrent VTE",
                    //                         "fr": null
                    //                     },
                    //                     "value": "recurrent",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": true,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": false
                    //             },
                    //             {
                    //             "id": 2,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "Are you already taking anticoagulants?  ",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": "Anticoagulants are “blood thinners” are used to treat and prevent prevent blood clots. ",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "radio",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "Yes",
                    //                         "fr": null
                    //                     },
                    //                     "value": "yes",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "No",
                    //                         "fr": null
                    //                     },
                    //                     "value": "no",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": true,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": false
                    //             },
                    //             {
                    //             "id": 3,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "Where is your Clot?",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "checkbox",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "My legs",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "Deep Vein Thrombosis (DVT) a blood clot occurring in the deep veins of the body, most commonly in the legs. <i ui-icon='info' info-target='DVT'></i>"
                    //                     },
                    //                     "value": "legs",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "My lungs",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "Pulmonary Embolism (PE) A blood clot that occurs in your lungs, which has travelled from legs or pelvis. <i ui-icon='info' info-target='PE'></i>"
                    //                     },
                    //                     "value": "lungs",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 3,
                    //                     "text": {
                    //                         "en": "It is somewhere else, I am not sure",
                    //                         "fr": null
                    //                     },
                    //                     "value": "not_sure",
                    //                     "exclusive": true,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": true,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": false
                    //             },
                    //             {
                    //             "id": 4,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "Why do I have a Clot?",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": "There are many factors that could have contributed to you getting a Clot. Please select all that apply to you:",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "checkbox",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "Recent Accident",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "recent_accident",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "Recent Surgery",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "recent_surgery",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 3,
                    //                     "text": {
                    //                         "en": "Recent COVID-19 infection",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "recent_covid_19",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 4,
                    //                     "text": {
                    //                         "en": "Estrogen therapy",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "estrogen_therapy",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 5,
                    //                     "text": {
                    //                         "en": "minor injury",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "minor_injury",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 6,
                    //                     "text": {
                    //                         "en": "cast (immobilization) ",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "cast",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 7,
                    //                     "text": {
                    //                         "en": "pregnancy ",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that these contributed to your Clot- your clot was a Provoked Clot <i ui-icon='info' info-target='provoked'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "pregnancy",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 8,
                    //                     "text": {
                    //                         "en": "None of the above apply to me",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "It is likely that your is an unprovoked Clot <i ui-icon='info' info-target='unprovoked'></i>"
                    //                     },
                    //                     "value": "none",
                    //                     "exclusive": true,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": true,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": false
                    //             },
                    //             {
                    //             "id": 5,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "Which one of these medications has your doctor started you on?",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": "<i ui-icon='info' info-target='DOACs'></i><span ui-link='info' info-target='DOACs'>DOACs</span>",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "radio",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "Rivaroxaban or Xarelto™",
                    //                         "fr": null
                    //                     },
                    //                     "value": "rivaroxaban",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "Apixaban or Eliquis™",
                    //                         "fr": null
                    //                     },
                    //                     "value": "apixaban",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 3,
                    //                     "text": {
                    //                         "en": "Edoxaban or Lixiana™",
                    //                         "fr": null
                    //                     },
                    //                     "value": "edoxaban",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 4,
                    //                     "text": {
                    //                         "en": "Dabigatran or Pradaxa™",
                    //                         "fr": null
                    //                     },
                    //                     "value": "dabigatran",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 5,
                    //                     "text": {
                    //                         "en": "Warfarin",
                    //                         "fr": null
                    //                     },
                    //                     "value": "warfarin",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": true,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": false
                    //             },
                    //             {
                    //             "id": 6,
                    //             "header": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "body": {
                    //                 "text": {
                    //                 "en": "What additional information do you may want to know:",
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "subtext": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "footer": {
                    //                 "text": {
                    //                 "en": null,
                    //                 "fr": null
                    //                 }
                    //             },
                    //             "groups": [
                    //                 {
                    //                 "id": 1,
                    //                 "type": "checkbox",
                    //                 "text": {
                    //                     "en": null,
                    //                     "fr": null
                    //                 },
                    //                 "choices": [
                    //                     {
                    //                     "id": 1,
                    //                     "text": {
                    //                         "en": "What are my chances of getting a clot again?",
                    //                         "fr": null
                    //                     },
                    //                     "value": "chances_of_another_clot",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 2,
                    //                     "text": {
                    //                         "en": "How long will I need to take an anticoagulant?",
                    //                         "fr": null
                    //                     },
                    //                     "side": {
                    //                         "en": "Duration of Therapy <i ui-icon='info' info-target='DOACs'></i>",
                    //                         "fr": null
                    //                     },
                    //                     "value": "duration_of_therapy",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 3,
                    //                     "text": {
                    //                         "en": "What is my risk of Bleeding?",
                    //                         "fr": null
                    //                     },
                    //                     "value": "risk_of_bleeding",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 4,
                    //                     "text": {
                    //                         "en": "What the cost of these drugs?",
                    //                         "fr": null
                    //                     },
                    //                     "value": "cost_of_drugs",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 5,
                    //                     "text": {
                    //                         "en": "Is there a Reversing agent for all these drugs?",
                    //                         "fr": null
                    //                     },
                    //                     "value": "reversing_agent",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 6,
                    //                     "text": {
                    //                         "en": "Do I have to be on a special diet?",
                    //                         "fr": null
                    //                     },
                    //                     "value": "special_diet",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 7,
                    //                     "text": {
                    //                         "en": "When can I start exercising again? ",
                    //                         "fr": null
                    //                     },
                    //                     "value": "start_exercising_again",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 8,
                    //                     "text": {
                    //                         "en": "How do I know the leg clot is back (DVT)?",
                    //                         "fr": null
                    //                     },
                    //                     "value": "signs_of_recurrent_dvt",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 9,
                    //                     "text": {
                    //                         "en": "How do i know if the clot in the Lung (PE) is back",
                    //                         "fr": null
                    //                     },
                    //                     "value": "signs_of_recurrent_pe",
                    //                     "exclusive": false,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     },
                    //                     {
                    //                     "id": 10,
                    //                     "text": {
                    //                         "en": "None of the above",
                    //                         "fr": null
                    //                     },
                    //                     "value": "none",
                    //                     "exclusive": true,
                    //                     "other": false,
                    //                     "other_text": {
                    //                         "en": "Please specify",
                    //                         "fr": "Veuillez préciser"
                    //                     },
                    //                     "display": true,
                    //                     "displayLogic": {
                    //                         "all": [],
                    //                         "any": [],
                    //                         "none": []
                    //                     }
                    //                     }
                    //                 ],
                    //                 "display": true,
                    //                 "displayLogic": {
                    //                     "all": [],
                    //                     "any": [],
                    //                     "none": []
                    //                 }
                    //                 }
                    //             ],
                    //             "display": true,
                    //             "displayLogic": {
                    //                 "all": [],
                    //                 "any": [],
                    //                 "none": []
                    //             },
                    //             "actions": null,
                    //             "system": false
                    //             }
                    //         ],
                    //         "info": {
                    //             "DVT": {
                    //             "content": {
                    //                 "en": `
                    //                 <table border='1'>
                    //                 <tr>
                    //                     <td colspan='2'>
                    //                     Signs:
                    //                         <ul>
                    //                         <li>Swelling in impacted limb (distal or farther down to clot) </li>
                    //                         <li>Pain and tenderness in affected area</li>
                    //                         <li>Warmth in affected area</li>
                    //                         <li>Discolouration in affected area </li>
                    //                         </ul>
                    //                     </td>
                    //                 </tr>
                    //                 <tr>
                    //                     <td>
                    //                     Locations 
                    //                     Distal DVT: below the knee (20-30%) 
                    //                     <ul>
                    //                     <li>Tibial veins </li>
                    //                     <li>Peroneal veins</li>
                    //                     </ul>
                    //                     </td>

                    //                     <td>
                    //                     Risks for Distal DVTs 
                    //                     <ul>
                    //                     <li>Likelihood of clot growth/extension into veins above the knee (e.g., popliteal) is ~15% if untreated</li>
                    //                     <li>Those confined to muscular veins of calf have lower risk of extension than in axial veins</li>
                    //                     </ul>
                    //                     </td>
                    //                 </tr>
                    //                 <tr>
                    //                     <td>
                    //                     Proximal DVT: above the knee (70-80%)
                    //                     <ul>
                    //                     <li>Popliteal vein</li>
                    //                     <li>Superficial or deep femoral vein</li>
                    //                     <li>Common femoral vein</li>
                    //                     <li>External iliac vein</li>
                    //                     </ul>
                    //                     </td>

                    //                     <td>
                    //                     Risk for Proximal DVT
                    //                     <ul>
                    //                     <li>Those confined to muscular veins of calf have lower risk of extension than in axial veins</li>
                    //                     </ul>
                    //                     </td>
                    //                 </tr>
                    //                 <tr>
                    //                     <td>
                    //                     Acute consequences: 
                    //                     <ul>
                    //                     <li>Development of PE</li>
                    //                     </ul>
                    //                     </td>
                    //                 </tr>
                    //                 <tr>
                    //                     <td>
                    //                     Monitoring over time: 
                    //                     <ul>
                    //                     <li>Limb swelling, color, pain, use and limitations </li>
                    //                     </ul>
                    //                     Chronic complications:
                    //                     <ul>
                    //                     <li>Post-thrombotic syndrome</li>
                    //                     </ul>
                    //                     </td>
                    //                 </tr>
                    //                 </table>

                    //                 Links (thrombosis canada): <br>
                    //                 <a href='https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-You-Have-a-DVT_Oct2016.pdf' target='_blank'>https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-You-Have-a-DVT_Oct2016.pdf</a>
                                    
                    //                 Arm DVT: <br>
                    //                 <a href='https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-You-Have-an-Arm-DVT_Oct2016.pdf' target='_blank'>https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-You-Have-an-Arm-DVT_Oct2016.pdf</a>
                                    
                                    
                    //                 Possible complications: 
                                    
                    //                 Post thrombotic syndrome 
                    //                 <ul>
                    //                 <li>Chronic condition that develops in 20-50% of patients following DVT </li>
                    //                 <li>How does DVT lead to PTS? </li>
                    //                 <ul>
                    //                     <li>Residual obstruction in the veins can lead to increased venous pressure</li>
                    //                     <li>Backflow valves within the veins may be damaged, thereby preventing their ability to assist with forward movement of blood flow</li>
                    //                     <li>This can lead to swelling in the leg and if progresses, can result in poor circulation with leg ulceration</li>
                    //                 </ul>
                    //                 <li>  Clinical presentation (symptoms in leg)</li>
                    //                 <ul>
                    //                     <li>Heaviness or fatigue </li>
                    //                     <ul><li>Sign = swelling (edema) </li></ul>
                    //                     <li>Pain </li>
                    //                     <ul>
                    //                     <li>Often described as a dull ache </li>
                    //                     <li>Sign = perimalleolar telangiectasia (“spider veins” - small dilated blood vessels near surface of leg) </lli>
                    //                     </ul>
                    //                     <li>Swelling </li>
                    //                     <ul>
                    //                     <li>Chronic or longstanding in nature</li>
                    //                     <li>Sign = varicose veins (swollen, twisted veins near surface of skin) </lli>
                    //                     </ul>
                    //                     <li>Itching </li>
                    //                     <ul><li>Sign = hyperpigmentation (redness of skin) </li></ul>
                    //                     <li>Paresthesia (tingling sensation in skin) </li>
                    //                     <ul><li>Sign = cyanosis (blue coloration to skin) </li></ul>
                    //                     <li>Pain when walking </li>
                    //                     <li>Symptom pattern: </li>
                    //                     <ul>
                    //                     <li>Become worse with activity, standing, or walking</li>
                    //                     <li>Become better with rest and leg elevation </lli>
                    //                     </ul>
                    //                 </ul>
                    //                 </ul>            
                    //                 `,
                    //                 "fr": null
                    //             }
                    //             },
                    //             "PE": {
                    //             "content": {
                    //                 "en": `
                    //                 Symptoms: 
                    //                 <ul>
                    //                 <li>Sudden onset shortness of breath (dyspnea) </li>
                    //                 <li>Pleuritic chest pain (sudden and intense pain upon inhaling/exhaling) </li>
                    //                 <li>Syncope (fainting) </li>
                    //                 </ul>
                    //                 Signs: 
                    //                 <ul>
                    //                 <li>Rapid breathing (tachypnea) </li>
                    //                 <li>Increased heart rate (tachycardia)</li>
                    //                 <li>Low oxygen in blood (hypoxemia) </li>
                    //                 <ul><li>Can result in confusion, restlessness, shortness of breath </li></ul>
                    //                 <li>Decreased blood pressure (hypotension)</li>
                    //                 <li>Coughing up blood or bloody mucus (hemoptysis) </li>
                    //                 </ul>
                    //                 Acute consequences: 
                    //                 <ul>
                    //                 <li>Death (3rd most common cardiovascular cause) </li>
                    //                 <li>Right ventricular failure (heart failure) </li>
                    //                 </ul>
                    //                 Monitoring over time: 
                    //                 <ul>
                    //                 <li>Pain and breathing</li>
                    //                 <li>Right heart pressure </li>
                    //                 <li>Activity limitations </li>
                    //                 </ul>
                    //                 Chronic complications
                    //                 <ul>
                    //                 <li>CTEPH (chronic thromboembolytic pulmonary hypertension) </li>
                    //                 </ul>
                                    
                    //                 Links (thrombosis canada): <br>
                    //                 <a href='https://thrombosiscanada.ca/wp-uploads/uploads/2016/03/Pulmonary-Embolism-Jan-2021.pdf' target='_blank'>https://thrombosiscanada.ca/wp-uploads/uploads/2016/03/Pulmonary-Embolism-Jan-2021.pdf</a>

                    //                 <table>
                    //                 <tr>
                    //                 <td colspan='3' text-align='center'>
                    //                 <img src='arrow' style='width: 100%'/>
                    //                 </td>
                    //                 </tr>
                    //                 <tr>
                    //                 <td width='33%'>
                    //                     Acute Pulmonary Embolism 

                    //                     ~80% of  PEs 
                    //                     Results in death <1% 
                                        
                    //                     Symptoms: 
                    //                     Shortness of breath   
                    //                 </td>

                    //                 <td width='33%'>
                    //                     Submassive Pulmonary Embolism

                    //                     ~15% of PEs 
                    //                     Results in death 3-15%      
                    //                 </td>

                    //                 <td width='33%'>
                    //                     Massive Pulmonary Embolism

                    //                     <5% of PEs
                    //                     Results in death >15% 
                                        
                    //                     Symptoms: 
                    //                     Low blood pressure (hypotension)
                    //                     Decreased heart rate (bradycardia) 
                    //                     Chest pain 
                    //                     Shock    
                    //                 </td>

                    //                 </tr>

                    //                 </table>
                    //                 `,
                    //                 "fr": null
                    //             }
                    //             },
                    //             "provoked": {
                    //             "content": {
                    //                 "en": `
                    //                 Recurrent rates:
                    //                 <table>
                    //                 <tr>
                    //                 <td>
                    //                 Transient risk factors
                    //                 <ul> 
                    //                 <li>Surgical – cumulative risk of recurrence: 1% at 1 year; 5% at 5 years  (STRONG)</li>
                    //                 <li>Nonsurgical – cumulative risk of recurrence: 5% at 1 year; 15% at 5 years (WEAK)</li>
                    //                 </ul>            
                    //                 Persistent risk factors                         
                    //                 </td>
                    //                 </tr>
                    //                 </table>  
                                    
                    //                 <table>
                    //                 <tr>
                    //                 <td>Transient risk factors: </td>
                    //                 <td>Persistent risk factors: </td>
                    //                 </tr>
                    //                 <tr>
                    //                 <td>
                    //                 <ul>
                    //                     <li>Surgery </li>
                    //                     <li>Trauma </li>
                    //                     <li>Immobilization </li>
                    //                     <ul>
                    //                     <li>Bed rest </li>
                    //                     <li>Extremity injury resulting in a cast </li>
                    //                     <li>Long distance travel </li>
                    //                     <li>Estrogen </li>
                    //                     </ul>
                    //                     <ul>
                    //                     <li>Pregnancy </li>
                    //                     <li>Estrogen therapy </li>
                    //                     <li>Patient factors </li>
                    //                     <ul>
                    //                     <li>Obesity </li>
                    //                     <li>Smoking </li>
                    //                     </ul>
                    //                 </ul>
                                                    
                    //                 </td>
                    //                 <td>
                    //                 <ul>
                    //                     <li>Patient factors </li>
                    //                     <ul>
                    //                     <li>Family history of VTE</li>
                    //                     <li>Age (over 60) </li>
                    //                     <li>Genetic disorders </li>
                    //                     </ul>
                    //                     <ul>
                    //                     <li>Hereditary thrombophilia </li>
                    //                     </ul>
                    //                 </ul>
                                                    
                    //                 </td>
                    //                 </tr>
                    //                 </table>

                    //                 <div style='position: relative; width:100%;'>
                    //                 <div style='margin: 0 auto; position: relative; top: 100px; background-color: white; border: 1px solid red; padding: 5px; min-width: 50px;max-width: 200px; min-height:50px'>
                    //                 Hypercoagulability: increased tendency for blood to clot 

                    //                 Hormones (eg: estrogen) 
                    //                 Genetic risk factors or family history 
                    //                 Major surgery or trauma 
                    //                 Medical conditions (eg: cancer)                                   
                    //                 </div>
                    //                 <img src='triangle' style='width:100%'/>

                    //                 <div style='background-color: white; border: 1px solid red; padding: 5px; min-width: 50px;max-width: 200px; min-height:50px; position: absolute; bottom: 0px; left: 0px;'>
                    //                 Endothelial damage: injury to the walls of blood vessels 

                    //                 Endothelial dysfunction (eg: hypertension) 
                    //                 Endothelial injury (eg: surgery or trauma)                                           
                    //                 </div>
                    //                 <div style='background-color: white; border: 1px solid red; padding: 5px; min-width: 50px;max-width: 200px; min-height:50px; position: absolute; bottom: 0px; right: 0px;'>
                    //                 Stasis of blood: less circulation and overall movement of blood 

                    //                 Immobility (eg: bed rest, cast) 
                    //                 Venous obstruction 
                    //                 Cardiovascular disease 
                                                    
                    //                 </div>
                    //                 </div>
                    //                 `,
                    //                 "fr": null
                    //             }
                    //             },
                    //             "unprovoked": {
                    //             "content": {
                    //                 "en": `
                    //                 Recurrent rates
                    //                 <table>
                    //                 <tr>
                    //                 <td>Cumulative risk of recurrence </td>
                    //                 <td>10% at 1 year
                    //                 30% at 5 years 
                    //                 </td>
                    //                 </tr>
                    //                 <tr>
                    //                 <td>Risk of recurrence following a second unprovoked VTE </td>
                    //                 <td>20% higher than after the first unprovoked VTE </td>
                    //                 </tr>
                    //                 <tr>
                    //                 <td>Sex differences </td>
                    //                 <td>Men have 75% higher risk of recurrence than women </td>
                    //                 </tr>
                    //                 </table>
                    //                 `,
                    //                 "fr": null
                    //             }
                    //             },
                    //             "DOACs": {
                    //             "content": {
                    //                 "en": `
                    //                 Symptoms: 
                    //                 <ul>
                    //                 <li>Sudden onset shortness of breath (dyspnea) </li>
                    //                 <li>Pleuritic chest pain (sudden and intense pain upon inhaling/exhaling) </li>
                    //                 <li>Syncope (fainting) </li>
                    //                 </ul>
                    //                 Signs: 
                    //                 <ul>
                    //                 <li>Rapid breathing (tachypnea) </li>
                    //                 <li>Increased heart rate (tachycardia)</li>
                    //                 <li>Low oxygen in blood (hypoxemia) </li>
                    //                 <ul><li>Can result in confusion, restlessness, shortness of breath</li></ul>
                    //                 <li>Decreased blood pressure (hypotension)</li>
                    //                 <li>Coughing up blood or bloody mucus (hemoptysis) </li>
                    //                 </ul>
                    //                 Acute consequences: 
                    //                 <ul>
                    //                 <li>Death (3rd most common cardiovascular cause) </li>
                    //                 <li>Right ventricular failure (heart failure) </li>
                    //                 </ul>
                    //                 Monitoring over time: 
                    //                 <ul>
                    //                 <li>Pain and breathing</li>
                    //                 <li>Right heart pressure </li>
                    //                 <li>Activity limitations </li>
                    //                 Chronic complications
                    //                 <li>CTEPH (chronic thromboembolytic pulmonary hypertension) </li>
                    //                 </ul>
                    //                 Links (thrombosis canada): 
                    //                 <a href="https://thrombosiscanada.ca/wp-uploads/uploads/2016/03/Pulmonary-Embolism-Jan-2021.pdf" target="_blank">https://thrombosiscanada.ca/wp-uploads/uploads/2016/03/Pulmonary-Embolism-Jan-2021.pdf</a> 
                                    
                    //                 `,
                    //                 "fr": null
                    //             }
                    //             },
                    //             "DoT": {
                    //             "content": {
                    //                 "en": `
                    //                 CHEST 2016:
                    //                 <ul>
                    //                 <li>Proximal DVT of the leg or PE provoked by surgery, treat 3 months</li>
                    //                 <li>Proximal DVT of the leg or PE provoked by a nonsurgical transient risk factor, treat 3 months (treat only 3 months over extended regardless of low, moderate or high bleed risk but higher weight if high bleed risk)</li>
                    //                 <li>Isolated distal DVT of leg provoked by surgery or by a nonsurgical transient risk factor, treat for 3 months </li>
                    //                 <li>Unprovoked leg DVT (isolated distal or proximal) or PE, treat for at least 3 months</li>
                    //                 <li>First unprovoked VTE (proximal leg DVT or PE) with low to moderate bleed risk, suggest extended OAC with no scheduled stop date; if high bleed risk recommend 3 months over extended therapy – sex and D-dimer may inform this decision; annual reassessment should occur</li>
                    //                 <li>Second unprovoked VTE with low bleeding risk, recommend extending therapy (no scheduled stop date); moderate bleed risk suggest extended therapy; high bleeding risk suggest 3 months over extended therapy (no stop date) </li>
                    //                 </ul>
                                    
                    //                 LINK (Thrombosis Canada)
                    //                 <br>
                    //                 <a href='https://thrombosiscanada.ca/wp-uploads/uploads/2021/01/7.-VTE-Duration-of-Treatment_23Jan2020.pdf' target='_blank'>https://thrombosiscanada.ca/wp-uploads/uploads/2021/01/7.-VTE-Duration-of-Treatment_23Jan2020.pdf</a>
                                    
                                                
                    //                 `,
                    //                 "fr": null
                    //             }
                    //             }
                    //         }
                    //     }
                    // }
                    let questions = response.data.questions
                    self.$set(self.questions = [])
                    for(let i=0; i<questions.length; i++){
                        let question = questions[i]
                        question.form = {}
                        self.makeActive(self.questions, i, question)
                    }

                    let info = response.data.info
                    for(let key in info){
                        let infoItem = info[key]
                        infoItem.form = {}
                        self.makeActive(self.info, key, infoItem)
                    }
                    resolve()
                },function(response){
                    reject(response)
                })
            })

            let promises = [
                // algorithmInfo,
                questions
            ]

            Promise.all(promises).then(()=>{
                self.ready = true
                setTimeout(function(){
                    self.linkImages()
                },800)
            })

        },
        makeActive: function(target, item, source){
            let self = this
            self.$set(target, item, source)
        },
        customUpdate: function(source){
            let form_data = this.form_data
            let api_data = this.api_data

            /*
            TODO:
            - investigate repeated null change.  

            */

            if(source=='question'){
                if(form_data[2].group[1].value=='yes'){
                    setTimeout(function(){
                        form_data[2].group[1].value=null
                    },800)
                    alert('An exit message should be displayed')
                }

                if(form_data[4].group[1].value.length>0 && !form_data[4].group[1].value.includes('none')){
                    form_data[100].group[1].value = 'true'
                }else if(form_data[4].group[1].value.includes('none')){
                    form_data[100].group[1].value = 'false'
                }
            }

            if(source=='api_data'){
                if(api_data.factors.provoked_vte=='false'){
                    form_data[4].group[1].value=['none']
                }
            }
        },
        questionUpdate: function(data){
            function includeFactors(forInclusion, api_data, algorithmInfo){
                for(let factorKey in forInclusion){
                    let list = forInclusion[factorKey]
                    api_data.factors[factorKey]
                    if(typeof api_data.factors[factorKey]=='object'){

                        let factor = algorithmInfo.factorMap[factorKey]
                        if(factor){
                            if(!factor.options.multiselect){
                                api_data.factors[factorKey] = list[0]
                            }else{
                                let filtered = tools.object.filter(api_data.factors[factorKey], list)
                                api_data.factors[factorKey] = filtered.concat(list)
                            }
                        }
                    }else{
                        //If global factors set as string
                        if(typeof api_data.factors[factorKey]=='string' && !list.includes(api_data.factors[factorKey])){
                            api_data.factors[factorKey] = list[0]
                        }
                    }
                }
            }

            function removeFactors(forRemoval, api_data){
                for(let factorKey in forRemoval){
                    let remove = forRemoval[factorKey]
                    api_data.factors[factorKey]
                    if(api_data.factors[factorKey]!=null && typeof api_data.factors[factorKey]=='object' && api_data.factors[factorKey].length>0){
                        //Remove if global factors is an array - only remove mapped items
                        let filtered = tools.object.filter(api_data.factors[factorKey], remove)
                        api_data.factors[factorKey] = filtered
                    }else{
                        //Remove if global factors set as string
                        if(typeof api_data.factors[factorKey]=='string' && remove.includes(api_data.factors[factorKey])){
                            api_data.factors[factorKey] = []
                        }
                    }
                }
            }            

            let questionMapping = this.questionMapping
            let question_id = data.question.id
            let form_data = data.form

            if(questionMapping[question_id]){
                let question = questionMapping[question_id]
                
                for(let group_id in question.group){
                    let group = question.group[group_id]

                    let multiple = tools.object.isArray(form_data.group[group_id].value)
                    
                    let mappings = group.choice
                    let answer = form_data.group[group_id].value!=null && form_data.group[group_id].value.length>0 ? typeof form_data.group[group_id].value=='object' ? form_data.group[group_id].value : [form_data.group[group_id].value] : null
                    
                    let triggerSet = answer!=null ? Object.keys(mappings).some(r=>answer.indexOf(r)>=0) : false
                    
                    if(triggerSet){
                        //Add mapping items
                        let forInclusion = {
                            factors: {},
                            values: {}
                        }

                        for(let a=0; a<answer.length; a++){
                            let value = answer[a]
                            let items = mappings[value]

                            for(let i=0; i<items.length; i++){
                                let item = items[i]
                                if(!forInclusion[item.container][item.key]){
                                    forInclusion[item.container][item.key] = []
                                }

                                if(!forInclusion[item.container][item.key].includes(item.value)){
                                    forInclusion[item.container][item.key].push(item.value)
                                }
                            }

                        }
                        
                        if(Object.keys(forInclusion.factors).length>0){
                            // console.log('trigger - includeFactors',{
                            //     forInclusion,
                            //     mappings
                            // })
                            includeFactors(forInclusion.factors, this.api_data, this.algorithmInfo)
                        }  
                        
                        if(multiple){
                            let forRemoval = {
                                factors: {},
                                values: {}
                            }

                            for(let choice_trigger in mappings){
                                if(!answer.includes(choice_trigger)){
                                    let items = mappings[choice_trigger]
        
                                    for(let i=0; i<items.length; i++){
                                        let item = items[i]
                                        if(!forRemoval[item.container][item.key]){
                                            forRemoval[item.container][item.key] = []
                                        }
                                        if(!forRemoval[item.container][item.key].includes(item.value)){
                                            forRemoval[item.container][item.key].push(item.value)
                                        }
                                    }
                                }
                            }
                            if(Object.keys(forRemoval.factors).length>0){
                                removeFactors(forRemoval.factors, this.api_data)
                            }
                        }

                    }else{
                        //Clear by mapping items
                        let forRemoval = {
                            factors: {},
                            values: {}
                        }
                        for(let choice_trigger in mappings){
                            let items = mappings[choice_trigger]

                            for(let i=0; i<items.length; i++){
                                let item = items[i]
                                if(!forRemoval[item.container][item.key]){
                                    forRemoval[item.container][item.key] = []
                                }
                                if(!forRemoval[item.container][item.key].includes(item.value)){
                                    forRemoval[item.container][item.key].push(item.value)
                                }
                            }
                        }

                        if(Object.keys(forRemoval.factors).length>0){
                            // console.log('no trigger - clear',{
                            //     forRemoval,
                            //     mappings
                            // })
                            removeFactors(forRemoval.factors, this.api_data)
                        }
                    }                        
                }
            }

            if(this.ready && this.customUpdate){
                this.customUpdate('question')
            }
        },
        api_dataUpdate: function(){
            function filter(list, remove){
                let filterList = typeof remove=='string' ? [remove] : remove
                let filtered = list.filter(
                    function(e) {
                        return this.indexOf(e) < 0;
                    },
                    filterList
                )

                return filtered
            }
            let form_data = this.form_data
            let api_data = this.api_data
            let reverseLookup = this.reverseQuestionMapping
            let questionMap = this.questionMap
            for(let factorKey in reverseLookup.factors){
                let global = api_data.factors[factorKey]

                //Empty Global - Clear linked choices
                if(global==null || (global!=null && global.length==0)){
                    let targets = reverseLookup.factors[factorKey]
                    
                    for(let factorValue in targets){
                        let questions = targets[factorValue]

                        for(let i=0; i< questions.length; i++){
                            let question = questions[i]
                            let value = form_data[question.question_id].group[question.group_id].value

                            if(typeof value=='string' && value==question.choice_value){
                                // console.log('api clear string',{
                                //     value, question
                                // })
                                form_data[question.question_id].group[question.group_id].value = null
                            }
                            
                            if(value!=null && typeof value=='object' && value.includes(question.choice_value)){
                                // console.log('api clear object',{
                                //     value, question
                                // })
                                form_data[question.question_id].group[question.group_id].value = filter(form_data[question.question_id].group[question.group_id].value, question.choice_value)
                            }
                        }
                    }
                }
                
                
                //Has global - Set linked choices
                if(global!=null && global.length>0){
                    if(typeof global=='string'){
                        let targets = reverseLookup.factors[factorKey][global]
                        for(let index in targets){
                            let question = targets[index]
                            if(questionMap[question.question_id]){
                                form_data[question.question_id].group[question.group_id].value = question.choice_value
                            }else{
                                console.error('Missing mapping target',question)
                            }
                        }
                    }
                    if(typeof global=='object'){
                        let targets = reverseLookup.factors[factorKey]

                        for(let factorValue in targets){
                            let questions = targets[factorValue]

                            if(global.includes(factorValue)){
                                // console.log('api - check')
                                for(let i=0; i< questions.length; i++){
                                    let question = questions[i]
                                    if(!form_data[question.question_id].group[question.group_id].value.includes(question.choice_value)){
                                        form_data[question.question_id].group[question.group_id].value.push(question.choice_value)
                                    }
                                }
                            }else{
                                // console.log('api - uncheck')
                                for(let i=0; i< questions.length; i++){
                                    let question = questions[i]
                                    if(form_data[question.question_id].group[question.group_id].value.includes(question.choice_value)){
                                        form_data[question.question_id].group[question.group_id].value = filter(form_data[question.question_id].group[question.group_id].value, question.choice_value)
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if(this.ready && this.customUpdate){
                this.customUpdate('api_data')
            }
        },
        getPDF: function(){
            let self = this
            self.linkImages()

            setTimeout(function(){
                let html = document.getElementById('pdf_html').innerHTML
                self.sendRequest('downloadResponse',process.env.VUE_APP_API+'/makePDF',{
                    html
                }).then(function(response){
                    console.log('makePDF response',response)
                })
            },2000)
        }
    },
    computed: {
        form_data: function(){
            let output = {}
            let questions = this.questions

            if(questions!=null){
                for(let i=0; i<questions.length; i++){
                    let question = questions[i]
                    output[question.id] = question.form
                }
    
                return output
            }

            return null
        },
        questionMap: function(){
            let questions = this.questions
            let output = {}
            if(questions){
                output = this.returnMap(questions,'id')
            }

            return output
        },
        questionMapping: function(){
            let output = {}
            let questions = this.questions
            let algorithmInfo = this.algorithmInfo
            if(questions!=null && algorithmInfo!=null){
                output[100] = {
                    group: {
                        1: {
                            choice: {
                                'true': [{container: 'factors', key: 'provoked_vte', value: 'true'}],
                                'false': [{container: 'factors', key: 'provoked_vte', value: 'false'}]
                            }
                        }
                    }
                }
                output[1] = {
                    group: {
                        1: {
                            choice: {
                                'first': [{container: 'factors', key: 'vte_occurance', value: 'first'}],
                                'recurrent': [{container: 'factors', key: 'vte_occurance', value: 'recurrent'}]
                            }
                        }
                    }
                }
                output[3] = {
                    group: {
                        1: {
                            choice: {
                                'legs': [{container: 'factors', key: 'clot_location', value: 'legs'}],
                                'lungs': [{container: 'factors', key: 'clot_location', value: 'lungs'}]
                            }
                        }
                    }
                }
                return output
            }

            return null

        },
        reverseQuestionMapping: function(){
            let questionMapping = this.questionMapping
            let output = {
                factors: {},
                values: {}
            }

            for(let question_id in questionMapping){
                let question = questionMapping[question_id]

                for(let group_id in question.group){
                    let group = question.group[group_id]
                    
                    
                    for(let choice_value in group.choice){
                        let factors = group.choice[choice_value]
                        for(let i=0; i<factors.length; i++){
                            let factor = factors[i]
                            if(!output[factor.container][factor.key]){
                                output[factor.container][factor.key] = {}
                            }
                            if(!output[factor.container][factor.key][factor.value]){
                                output[factor.container][factor.key][factor.value] = []
                            }
                            if(!output[factor.container][factor.key][factor.value].includes(choice_value)){
                                output[factor.container][factor.key][factor.value].push({
                                    question_id, group_id, choice_value
                                })
                            }
                            // output[factor.container][factor.key].push({
                            //     question_id,
                            //     group_id,
                            //     choice_value
                            // })
                        }
                    }


                }
            }
            

            return output
        },
        images: function(){
            return {
                arrow,
                triangle
            }
        }
    },
    watch: {
        api_data: {
            deep: true,
            handler: function(){
                this.$emit('input',this.api_data)
                if(this.ready){
                    this.api_dataUpdate()
                }


            }
        }
    }
}
</script>

<style>

</style>